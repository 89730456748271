import { ImageFormat } from '@sanity/image-url/lib/types/types'
import { useEffect, useState } from 'react'

import { sanityImageBuilder } from 'utils/sanity/sanityUtils'

/*  ------------------------------ */
/*  Image helpers
/*  ------------------------------ */

export function numberNotationFormat(count: number) {
  // Safari 14.1.2 has a bug that breaks NumberFormat when it uses unsupported configuration
  try {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 1,
      notation: 'compact',
      compactDisplay: 'short'
    }).format(Number(count))
  } catch (err) {
    return String(Number(count))
  }
}

export function buildSrc(
  image: any,
  {
    width,
    height,
    format,
    quality
  }: {
    width?: number
    height?: number
    format?: ImageFormat
    quality?: number
  }
): any {
  let imgSrc = sanityImageBuilder.image(image)

  if (width) {
    imgSrc = imgSrc.width(Math.round(width))
  }

  if (height) {
    imgSrc = imgSrc.height(Math.round(height))
  }

  if (format) {
    imgSrc = imgSrc.format(format)
  }

  if (quality) {
    imgSrc = imgSrc.quality(quality)
  }

  return imgSrc.fit('max').auto('format').url()
}

export function buildSrcSet(
  image: any,
  {
    srcSizes,
    aspect,
    format,
    quality
  }: { srcSizes?: number[]; aspect?: number; format?: ImageFormat; quality?: number }
) {
  const sizes = srcSizes?.map((width: number) => {
    let imgSrc = buildSrc(image, {
      ...{ width },
      height: aspect && Math.round(width * aspect) / 100,
      ...{ format },
      ...{ quality }
    })

    if (format) {
      imgSrc = imgSrc.format(format)
    }

    return `${imgSrc} ${width}w`
  })

  return sizes?.join(',')
}

export function isMobileSafari() {
  return !!(
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/)
  )
}

export function useWindowSize() {
  function getSize() {
    return {
      width: window.innerWidth || 0,
      height: window.innerHeight || 0
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export const getCanonicalOrigin = () => {
  return window.location.origin
}

export const getCanonicalUrl = (path: string) => {
  const acceptedSearchParams = ['page', 'lastId', 'reverse']
  const url = new URL(`${getCanonicalOrigin()}${path}`)

  for (const entry of Array.from(url.searchParams.entries())) {
    if (!acceptedSearchParams.includes(entry[0])) {
      url.searchParams.delete(entry[0])
    }
  }

  let stringUrl = url.toString()
  if (stringUrl.endsWith('/')) {
    stringUrl = stringUrl.slice(0, -1)
  }

  return stringUrl
}

export const withinDays = (dateToCheck: string, numDays = 14) => {
  if (dateToCheck === undefined) return false // remove this?

  const dateLimit = new Date()
  dateLimit.setDate(dateLimit.getDate() - numDays)
  if (new Date(dateToCheck) > dateLimit) {
    return true
  }
  return false
}

// replace template tags with values
export const replaceTemplateTags = (
  string: string,
  templateTags: {
    tag: string
    value?: string
  }[] = []
) => {
  if (!string) return

  let newString = string

  templateTags.map((v) => {
    newString = newString.replace(new RegExp(v.tag, 'g'), v.value || '')
  })

  return newString
}

export const slugify = (string: string) => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const getTableOfContentsSlug = ({ title, id }: { title: string; id: string }) => {
  return `${slugify(title)}-${id}`
}
