import projectIcon from 'icon--project.svg'

interface IModuleProgramIcon {
  programIconImageUrl: string
  isProject: boolean
}

const ModuleProgramIcon = ({ programIconImageUrl, isProject }: IModuleProgramIcon) => {
  const iconSrc = isProject ? projectIcon : programIconImageUrl
  return <img src={iconSrc} alt="" />
}

export default ModuleProgramIcon
