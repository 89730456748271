import AvatarWithBadge from 'components/AvatarWithBadge'
import CompanyBadge from 'components/CompanyBadge'
import { CheckIcon, PlayCircleBorderIcon } from 'components/icons'

import { ClipPartsFragment } from 'gql'

export interface HighlightsCardProps {
  isWide?: boolean
  className?: string
  clip: ClipPartsFragment
  onClick?: () => void
  contentClickDisabled?: boolean
  hasAddAndRemoveEnabled?: boolean
}

const CheckMark = ({ show }: { show: boolean }) =>
  show ? (
    <div className="absolute flex h-full w-full items-end justify-end bg-rb-blue-50/30">
      <CheckIcon className="mb-4 mr-4 fill-current text-white" />
    </div>
  ) : null

const ClipDuration = ({ duration }: { duration: number }) => (
  <span className="flex rounded-sm bg-rb-gray-50/75 py-0.5 px-1.5 text-xs font-medium tracking-wider">
    <PlayCircleBorderIcon className="mr-2 h-4 w-4 fill-current" />
    {`${Math.floor(duration / 60)}:${(duration % 60).toString().padStart(2, '0')}`}
  </span>
)

const HighlightsCard = ({
  isWide = false,
  className = '',
  clip,
  onClick,
  contentClickDisabled = false,
  hasAddAndRemoveEnabled
}: HighlightsCardProps) => {
  const hasCompanyBadge = !!(clip.company?.cardLogoUrl && clip.company?.name)
  const handleClick = () => {
    if (contentClickDisabled || !onClick) return
    onClick()
  }
  const wideCard = (
    <div
      data-test="cohort-viewer-highlights"
      onClick={handleClick}
      className={`${className} group flex self-start overflow-hidden rounded-sm opacity-90 ${
        contentClickDisabled
          ? 'border'
          : 'cursor-pointer shadow-card hover:opacity-100 hover:shadow-card-hover'
      }`}
    >
      <div className="relative w-4/12 bg-rb-gray-200">
        <div className="aspect-w-8 aspect-h-7 h-full w-full">
          <CheckMark show={clip.hasBeenViewedByCurrentUser} />
          <img
            className="h-full w-full object-cover object-center"
            src={clip.thumbnailUrl}
          />
        </div>
        <div className="absolute left-3 bottom-3 flex w-full items-center justify-between">
          {clip.duration && <ClipDuration duration={clip.duration} />}
        </div>
      </div>
      <div className="flex w-9/12 flex-col justify-between bg-white px-4 py-5 md:py-2">
        <span
          className={`max-w-xs text-sm font-medium ${
            hasAddAndRemoveEnabled ? 'mr-7' : ''
          }`}
        >
          {clip.title}
        </span>
        <div className="mt-4 flex w-full items-center justify-between">
          {Boolean(clip.users.length > 0) && (
            <div className="flex items-center">
              <AvatarWithBadge
                avatarUrl={clip.users[0].avatarUrl}
                imgClasses="w-5 aspect-[1/1]"
                hasBadge={false}
              />
              <span className="ml-2 text-xs font-medium">{clip.users[0].fullName}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const potraitCard = (
    <div
      data-test="cohort-viewer-highlights"
      onClick={onClick}
      className={`${className} group flex cursor-pointer flex-col overflow-hidden rounded-sm opacity-90 shadow-card hover:opacity-100 hover:shadow-card-hover`}
    >
      {hasCompanyBadge && (
        <CompanyBadge
          name={clip.company?.name || ''}
          cardLogoUrl={clip.company?.cardLogoUrl || ''}
          className="absolute z-10 mt-2 ml-2"
        />
      )}
      <div className="relative bg-rb-gray-200">
        <CheckMark show={clip.hasBeenViewedByCurrentUser} />
        <img className="h-full w-full object-cover" src={clip.thumbnailUrl} alt="" />
        <div className="absolute bottom-0 mb-4 flex w-full items-center justify-between px-4">
          {clip.duration && <ClipDuration duration={clip.duration} />}
        </div>
      </div>
      <div className="flex h-full flex-col bg-white p-4">
        <span className="h-12 font-medium leading-5">{clip.title}</span>
        <div className="mt-4 flex w-full items-center justify-between">
          {Boolean(clip.users.length > 0) && (
            <div className="flex items-center">
              <AvatarWithBadge
                avatarUrl={clip.users[0].avatarUrl}
                imgClasses="w-8 aspect-[1/1]"
                hasBadge={false}
              />
              <div className="ml-2 text-xs">
                <span className="font-medium">{clip.users[0].fullName},</span>{' '}
                {clip.users[0].role}
                {clip.users[0].companyName ? ` @ ${clip.users[0].companyName}` : ''}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
  return isWide ? wideCard : potraitCard
}

export default HighlightsCard
