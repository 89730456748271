import React, { PropsWithChildren, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface IPopupMenuItem {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const PopupMenuItem = ({ onClick, children }: PropsWithChildren<IPopupMenuItem>) => (
  <button
    className="prevent-default w-full cursor-pointer bg-white py-3 pr-1.5 pl-4 text-left text-rb-gray-400 hover:bg-rb-gray-50 focus:outline-none"
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
)

interface IPopupMenu {
  id: string
  ukDropdown: string
  arrowPosition?: string
}

const PopupMenu = ({
  children,
  id,
  ukDropdown,
  arrowPosition = 'bottom',
  ...props
}: PropsWithChildren<IPopupMenu>) => {
  const [hoveringFirst, setHoveringFirst] = useState(false)
  const childrenList = React.Children.toArray(children)

  return (
    <div
      id={id}
      className="bg-rb-gray-50 p-0 text-rb-gray-400"
      uk-dropdown={ukDropdown}
      {...props}
    >
      {childrenList.map((child: React.ReactElement, index) => {
        const length =
          arrowPosition === 'top' ? index === 0 : childrenList.length - 1 === index
        const hoveringProps = length
          ? {
              onMouseEnter: () => setHoveringFirst(true),
              onMouseLeave: () => setHoveringFirst(false)
            }
          : {}

        return (
          <div key={`popup-menu-item-${index}`} {...hoveringProps}>
            {child}
          </div>
        )
      })}

      <span
        className={twMerge(
          'absolute left-1/2 h-4 w-4 -translate-x-1/2 transform bg-white shadow-popupMenuTooltip',
          hoveringFirst && 'bg-rb-gray-50',
          arrowPosition === 'top' ? '-top-2 rotate-[225deg]' : '-bottom-2 rotate-45'
        )}
      />
    </div>
  )
}

PopupMenu.Item = PopupMenuItem

export default PopupMenu
