const AddEventCalendarIcon = ({
  fill,
  className
}: {
  fill?: string
  className?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 11 10"
    className={className}
    fill={fill || 'white'}
  >
    <path
      fill={fill || 'white'}
      d="M3.375 0.5V0.875H1.875V1.25V2.75V3.62891C1.875 4.95686 1.72029 6.07244 0.859863 6.93286L0.75 7.04272V7.625H1.875V9.125H10.125V3.62891V1.25V0.875H8.625V0.5H7.875V0.875H4.125V0.5H3.375ZM2.625 1.625H3.375V2H4.125V1.625H7.875V2H8.625V1.625H9.375V2.375H2.625V1.625ZM2.625 3.125H9.375V3.62891C9.375 4.90474 9.21151 6.02929 8.44116 6.875H1.83252C2.47402 5.91286 2.625 4.77756 2.625 3.62891V3.125ZM3.375 3.875V4.625H4.125V3.875H3.375ZM4.875 3.875V4.625H5.625V3.875H4.875ZM6.375 3.875V4.625H7.125V3.875H6.375ZM7.875 3.875V4.625H8.625V3.875H7.875ZM3.25854 5.375L3 6.125H3.75L4.00854 5.375H3.25854ZM4.75854 5.375L4.5 6.125H5.25L5.50854 5.375H4.75854ZM6.25854 5.375L6 6.125H6.75L7.00854 5.375H6.25854ZM9.375 6.86475V8.375H2.625V7.625H8.78027L8.89014 7.51514C9.09118 7.31409 9.23288 7.08922 9.375 6.86475Z"
    />
  </svg>
)

export default AddEventCalendarIcon
