/*
  Util function to determine if a user has access to register for a cohort event
*/
import {
  CurrentUserPartsFragment,
  EventCurrentUserPartsFragment,
  EventPartsFragment
} from 'gql'

export default function eventSignUpAccess(
  currentUser: EventCurrentUserPartsFragment | CurrentUserPartsFragment,
  event: EventPartsFragment
): Boolean {
  if (event.kind !== 'Cohort Event') {
    return true
  }

  if (currentUser?.cohorts?.current[0] === undefined) {
    return false
  }

  const currentUserProgramId = currentUser?.cohorts?.current[0].cohort.cmsProgram.id
  return currentUserProgramId === event.cmsProgramOptional?.id
}

export function eventAccess(
  event: EventPartsFragment,
  currentUser?: EventCurrentUserPartsFragment | CurrentUserPartsFragment
): Boolean {
  if (event.isPublic) return true

  if (event.kind !== 'Cohort Event' || currentUser?.can.accessAllCohortEventReplays) {
    return true
  }

  if (!currentUser?.cohorts || Object.keys(currentUser?.cohorts).length === 0) {
    return false
  }

  const userCohortIds = [
    ...currentUser.cohorts.current,
    ...currentUser.cohorts.previous
  ].map((program) => program.cohort.id)

  const filteredCohorts = event.cohorts.filter((cohort) =>
    userCohortIds.includes(cohort.id)
  )

  if (filteredCohorts.length === 0) return false

  return true
}
