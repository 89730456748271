import { EventPartsFragment } from 'gql'

import { FULL_DATE_AND_TIME_ABBREV_MONTH_FORMAT, formatInTimezone } from 'utils/date'
import { EVENT_LINK_URL, makeUrl } from 'utils/url'

interface IAddToCalendar {
  event: EventPartsFragment
  timezone?: string | undefined | null
}

const AddToCalendar = ({ event, timezone }: IAddToCalendar) => {
  const getTime = (time: string) =>
    formatInTimezone(time, timezone, FULL_DATE_AND_TIME_ABBREV_MONTH_FORMAT)
  const startTime = getTime(event.startsAtUtc)
  const endTime = getTime(event.endsAtUtc)

  return (
    <div
      title="Add to Calendar"
      className="addeventatc"
      data-render="inline-buttons"
      onClick={(e) => e.stopPropagation()}
    >
      <span className="start">{startTime}</span>
      <span className="end">{endTime}</span>
      <span className="timezone">{timezone}</span>
      <span className="title">{event.name}</span>
      <span className="description">{event.description}</span>
      <span className="location">
        {makeUrl(`${window.location.origin}${EVENT_LINK_URL}`, { eventUUID: event.uuid })}
      </span>
      <span className="organizer">Reforge</span>
      <span className="organizer_email">hello@reforge.com</span>
      <span className="all_day_event">false</span>
    </div>
  )
}

export default AddToCalendar
