export interface Meta {
  currentPage: number
  totalPages: number
}

interface PaginatorProps {
  meta: Meta
  handlePagination: (page: number) => void
}

const Paginator = ({ meta, handlePagination }: PaginatorProps) => {
  const { currentPage, totalPages } = meta

  const firstPage = () => {
    handlePagination(1)
  }

  const nextPage = () => {
    handlePagination(currentPage + 1)
  }

  const previousPage = () => {
    handlePagination(currentPage - 1)
  }

  const lastPage = () => {
    handlePagination(totalPages)
  }

  if (totalPages === undefined || totalPages <= 1) return <div>&nbsp;</div>

  return (
    <div>
      <ul className="uk-pagination justify-center">
        <li className={currentPage === 1 ? 'uk-disabled' : ''}>
          <button
            className="dark-text cursor-pointer"
            aria-label="Previous"
            onClick={previousPage}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {currentPage > 2 && (
          <li>
            <button className="dark-text cursor-pointer" onClick={firstPage}>
              1
            </button>
          </li>
        )}
        {currentPage - 2 > 1 && (
          <li>
            <button className="uk-disabled">...</button>
          </li>
        )}
        {currentPage > 1 && (
          <li>
            <button className="dark-text cursor-pointer" onClick={previousPage}>
              {currentPage - 1}
            </button>
          </li>
        )}
        <li className="uk-active">
          <button className="dark-text">{currentPage}</button>
        </li>
        {currentPage < totalPages - 1 && (
          <li>
            <button className="dark-text cursor-pointer" onClick={nextPage}>
              {currentPage + 1}
            </button>
          </li>
        )}
        {currentPage + 2 < totalPages && (
          <li>
            <button className="dark-text uk-disabled">...</button>
          </li>
        )}
        {currentPage < totalPages && (
          <li>
            <button className="dark-text cursor-pointer" onClick={lastPage}>
              {totalPages}
            </button>
          </li>
        )}
        <li className={currentPage === totalPages ? 'uk-disabled' : ''}>
          <button
            className="dark-text cursor-pointer"
            aria-label="Next"
            onClick={nextPage}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </div>
  )
}

export default Paginator
