import React, { RefObject, useEffect, useRef, useState } from 'react'

const isInView = (element: { getBoundingClientRect: () => any }) => {
  const rect = element.getBoundingClientRect()

  return (
    rect.top >= 180 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const isAtBottomOfScreen = (element: { getBoundingClientRect: () => any }) => {
  const rect = element.getBoundingClientRect()
  return rect.bottom > window.innerHeight - 150
}

const isAtTopOfContainerElement = (
  element: { getBoundingClientRect: () => any },
  containerElement: { getBoundingClientRect: () => any }
) => {
  const rect = element.getBoundingClientRect()
  const rectContainer = containerElement.getBoundingClientRect()

  return rect.top < Math.abs(rectContainer.top) + 150
}

export const useDynamicMountPosition = (scrollableContainerRef: RefObject<any>) => {
  const mountingElementRef = useRef(null)

  const [mountingPoint, setMountingPoint] = React.useState<'top' | 'bottom'>('top')
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', () => setScrollY(window.pageYOffset))
    return () => {
      window.removeEventListener('scroll', () => setScrollY(window.pageYOffset))
    }
  })

  useEffect(() => {
    if (
      !scrollableContainerRef?.current &&
      mountingElementRef?.current &&
      !isInView(mountingElementRef.current)
    ) {
      setMountingPoint('bottom')
    }
  }, [scrollableContainerRef, mountingElementRef])

  useEffect(() => {
    if (scrollableContainerRef?.current && mountingElementRef?.current) {
      if (isAtBottomOfScreen(mountingElementRef?.current)) {
        setMountingPoint('top')
      } else if (
        isAtTopOfContainerElement(
          mountingElementRef?.current,
          scrollableContainerRef?.current
        )
      ) {
        setMountingPoint('bottom')
      }
    }
  }, [mountingElementRef, scrollableContainerRef, scrollY])

  return { mountingElementRef, mountingPoint }
}

export default useDynamicMountPosition
