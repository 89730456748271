import { string } from 'yup'

const emailValidation = string().trim().email()

export const isValidEmail = (email: string): boolean => {
  if (!email || email.endsWith('.con')) {
    return false
  }
  return emailValidation.isValidSync(email)
}
