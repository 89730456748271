import { useCallback, useEffect, useState } from 'react'

export default function useInfiniteScroll(
  fetchCallback: () => void,
  scrollableElementId?: string
) {
  const [shouldFetchMore, setShouldFetchMore] = useState(false)

  const handleScroll = useCallback(() => {
    let isEndOfScroll = false
    if (scrollableElementId) {
      const scrollableElement = document.getElementById(scrollableElementId)
      if (scrollableElement) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableElement
        isEndOfScroll = scrollHeight - scrollTop === clientHeight
      }
    } else {
      isEndOfScroll =
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 2
    }
    setShouldFetchMore(isEndOfScroll)
  }, [scrollableElementId])

  useEffect(() => {
    if (scrollableElementId) {
      const scrollableElement = document.getElementById(scrollableElementId)
      scrollableElement?.addEventListener('scroll', handleScroll)
      return () => scrollableElement?.removeEventListener('scroll', handleScroll)
    } else {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollableElementId, handleScroll])

  useEffect(() => {
    shouldFetchMore && fetchCallback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchMore])

  return [shouldFetchMore, setShouldFetchMore]
}
