import { ReactNode, useEffect, useState } from 'react'
import { PopoverPosition } from 'react-tiny-popover'
import { twMerge } from 'tailwind-merge'

import useHandleAddToFolder from 'domains/Collections/hooks/useHandleAddToFolder'
import useHandleRemoveFromFolder from 'domains/Collections/hooks/useHandleRemoveFromFolder'

import CardTooltip from 'components/CardTooltip'
import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'
import BookmarkIcon from 'components/icons/BookmarkIcon'

import {
  CreateBookmarkInput,
  ProgramBookmarkPartsFragment,
  useCreateBookmarkMutation,
  useFindBookmarkByIdLazyQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { onEnterKeyPress } from 'utils/keyboard'

interface manageBookmarkButtonProps {
  fetchOptions: any
  contentBookmarkId?: string | null
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  bookmarkInfo: CreateBookmarkInput
  isInSavedItems: boolean
  setIsInSavedItems: (isInSavedItems: boolean) => void
  dropdownPosition?: PopoverPosition
  svgOverride?: ReactNode
}

const ManageBookmarkButton = ({
  fetchOptions,
  contentBookmarkId,
  openAddToBookmarkFolderModal,
  bookmarkInfo,
  isInSavedItems,
  setIsInSavedItems,
  dropdownPosition = 'bottom',
  svgOverride
}: manageBookmarkButtonProps) => {
  const [bookmarkId, setBookmarkId] = useState<string | null>(contentBookmarkId || null)
  const [bookmark, setBookmark] = useState<ProgramBookmarkPartsFragment | null>(null)
  const [createBookmark] = useCreateBookmarkMutation({ ...fetchOptions })
  const { RemoveBookmarkFromFolder } = useHandleRemoveFromFolder(fetchOptions)
  const { AddBookmarkToFolder } = useHandleAddToFolder(fetchOptions)
  const { currentUser } = useCurrentUser()
  const paidUser =
    currentUser?.is.member || currentUser?.is.paidMember || currentUser?.is.planManager

  const [findBookmarkById] = useFindBookmarkByIdLazyQuery({})
  const manageBookmark = async (bookmarkId?: string | null) => {
    if (!bookmarkId) return

    const bookmarkToManage = bookmark || (await queryForBookmark())
    if (bookmarkToManage) {
      openAddToBookmarkFolderModal?.(bookmarkToManage)
    }
  }

  useEffect(() => {
    if (contentBookmarkId) {
      setBookmarkId(contentBookmarkId)
    }
  }, [contentBookmarkId])

  const queryForBookmark = async () => {
    if (!bookmarkId) return
    const response = await findBookmarkById({
      variables: {
        bookmarkId: bookmarkId.toString()
      }
    })
    setBookmark(response.data?.findBookmarkById || null)
    return response.data?.findBookmarkById || null
  }

  const addToDefaultFolder = async (bookmarkId?: string | null) => {
    if (!bookmarkId) return

    const { errors } = await AddBookmarkToFolder({
      variables: {
        input: {
          bookmarkId: bookmarkId,
          defaultBookmarkFolder: true
        }
      }
    })
    if (!errors) {
      await queryForBookmark()
    }
  }

  const sendDeleteBookmarkRequest = async () => {
    const bookmarkToDelete = bookmark || (await queryForBookmark())
    if (!bookmarkToDelete) return

    setIsInSavedItems(false)
    const { errors } = await RemoveBookmarkFromFolder({
      variables: {
        input: {
          bookmarkId: bookmarkToDelete.id,
          defaultBookmarkFolder: true
        }
      }
    })

    if (errors) {
      setIsInSavedItems(true)
    }
  }

  const saveBookmark = async () => {
    setIsInSavedItems(true)
    const { data } = await createBookmark({
      variables: {
        input: bookmarkInfo
      }
    })
    if (data?.createBookmark?.bookmark) {
      if (data?.createBookmark?.bookmark) {
        addToDefaultFolder(data?.createBookmark?.bookmark?.id)
      } else {
        setIsInSavedItems(false)
      }
      const onCtaClick = () => {
        paidUser
          ? openAddToBookmarkFolderModal?.(
              data?.createBookmark?.bookmark as ProgramBookmarkPartsFragment
            )
          : null
      }

      displayToast(
        <ToastCard
          message="Saved!"
          onCtaClick={onCtaClick}
          ctaText={paidUser ? 'Add to Collection' : undefined}
        />,
        { ...toastOptions, toastId: 'reforge-toast' }
      )
      setBookmarkId(data?.createBookmark?.bookmark.id)
    }
  }

  const handleSave = async () => {
    if (bookmarkId) {
      addToDefaultFolder(bookmarkId)
      const addedBookmark = bookmark || (await queryForBookmark())
      if (!addedBookmark) return
      setIsInSavedItems(true)
      displayToast(
        <ToastCard
          message="Saved!"
          onCtaClick={() => openAddToBookmarkFolderModal?.(addedBookmark)}
          ctaText={paidUser ? 'Add to Collection' : undefined}
        />,
        { ...toastOptions, toastId: 'reforge-toast' }
      )
    } else {
      saveBookmark()
    }
  }

  const savedToolTipText = isInSavedItems ? 'Manage save' : 'Save'

  const SaveIcon = (
    <BookmarkIcon
      className={`h-4 w-4 stroke-black ${isInSavedItems ? 'fill-current' : ''}`}
    />
  )

  return (
    <>
      {isInSavedItems && (
        <div
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onKeyUp={onEnterKeyPress((e) => {
            e.stopPropagation()
            e.preventDefault()
          })}
          role="button"
          tabIndex={0}
          className="flex items-center justify-center"
        >
          <div
            className="mb-[2px] flex select-none flex-col items-center"
            role="button"
            tabIndex={0}
          >
            <CardTooltip
              className={twMerge(
                'left-8 flex w-32 items-center justify-center p-0.5 font-sans'
              )}
              content={savedToolTipText}
              direction="left"
              useRelPos
              hideOnClick={true}
            >
              <DropdownContextMenu
                positions={[dropdownPosition]}
                reposition={true}
                boundaryInset={54}
                dismissOnClick
                triggerElement={<>{svgOverride || SaveIcon}</>}
                preventFurtherHover={true}
              >
                {paidUser && (
                  <DropdownContextMenu.DropdownItem
                    text="Save to Collection"
                    onClick={() => manageBookmark(bookmarkId)}
                  />
                )}
                <DropdownContextMenu.DropdownItem
                  text="Remove save"
                  onClick={sendDeleteBookmarkRequest}
                />
              </DropdownContextMenu>
            </CardTooltip>
          </div>
        </div>
      )}
      {!isInSavedItems && (
        <div
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onKeyUp={onEnterKeyPress((e) => {
            e.stopPropagation()
            e.preventDefault()
          })}
          role="button"
          tabIndex={0}
          className="flex items-center justify-center"
        >
          <div
            className="mb-[2px] flex select-none flex-col items-center justify-center"
            role="button"
            tabIndex={0}
            onClick={handleSave}
            onKeyUp={onEnterKeyPress(handleSave)}
          >
            <CardTooltip
              className={twMerge(
                'absolute mr-4 flex w-16 items-center justify-center p-0.5 font-sans'
              )}
              content="Save"
              direction="left"
              useRelPos
            >
              {svgOverride || SaveIcon}
            </CardTooltip>
          </div>
        </div>
      )}
    </>
  )
}

export default ManageBookmarkButton
