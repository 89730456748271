interface INextPhaseButton {
  nextPhaseNum?: number
  buttonText?: string
}

const NextPhaseButton = ({ nextPhaseNum, buttonText }: INextPhaseButton) => {
  const toggleBlockComplete = () => {
    const phaseElement = document.getElementById(`phase-${nextPhaseNum}`)
    if (phaseElement) {
      phaseElement.classList.remove('uk-hidden')
      const phases = document.querySelectorAll('.phase')
      const relatedContentDiv = document.getElementById('js-related-content')
      const feedbackDiv = document.getElementById('js-content-feedback')
      const summaryDiv = document.getElementById('js-content-summary')
      const endContentDiv = document.getElementById('end-center-content')
      phases.forEach((phEl) => {
        if (phEl.id !== phaseElement.id) {
          phEl.classList.add('uk-hidden')
        }
      })
      if (phases[phases.length - 1].id === phaseElement.id) {
        if (relatedContentDiv) {
          relatedContentDiv.classList.remove('uk-hidden')
        }
        if (feedbackDiv) {
          feedbackDiv.classList.remove('uk-hidden')
        }
        if (summaryDiv) {
          summaryDiv.classList.remove('uk-hidden')
        }
        if (endContentDiv) {
          endContentDiv.classList.remove('uk-hidden')
        }
      } else {
        if (relatedContentDiv) {
          relatedContentDiv.classList.add('uk-hidden')
        }
        if (feedbackDiv) {
          feedbackDiv.classList.add('uk-hidden')
        }
        if (summaryDiv) {
          summaryDiv.classList.add('uk-hidden')
        }
        if (endContentDiv) {
          endContentDiv.classList.remove('uk-hidden')
        }
      }
    }
    document.getElementById('page')?.scrollTo(0, 0)
  }

  // do not remove rf-button-next-section class from below unless refactoring the CMS implementation
  return (
    <button
      onClick={toggleBlockComplete}
      className="rf-button-next-section my-5 mt-6 flex w-full items-center justify-center border-1.5 border-rb-gray-500 bg-rb-gray-500 px-7 py-4 text-xs font-medium uppercase tracking-widest text-white transition duration-200 ease-in-out hover:border-rb-blue-100 hover:bg-rb-blue-100 hover:text-white"
    >
      {buttonText || 'Next Up: <Phase 2 Title>'}
    </button>
  )
}

const NextPhaseButtonCmsWrapper = () => {
  return (
    <div className="cms-next-section">
      <NextPhaseButton />
    </div>
  )
}

export { NextPhaseButton, NextPhaseButtonCmsWrapper }
