import UIkit from 'uikit'

export const showModal = (elementId: string, delay = 0) => {
  const node = document.getElementById(elementId)
  if (node) {
    setTimeout(() => UIkit.modal(node).show(), delay)
  }
}

export const hideModal = (elementId: string, delay = 0) => {
  const node = document.getElementById(elementId)
  if (node) {
    setTimeout(() => UIkit.modal(node).hide(), delay)
  }
}
