import clsx from 'clsx'
import { Fragment } from 'react'

import { EventSpeaker as IEventSpeaker } from 'gql'

// TODO: Replace with GraphQL
function getSpeakerName(eventSpeaker: IEventSpeaker | any) {
  if (eventSpeaker.name) return eventSpeaker.name
  if (eventSpeaker.user) return eventSpeaker?.user?.profile?.fullName
}

// TODO: Replace with GraphQL
function getSpeakerCompanyName(eventSpeaker: IEventSpeaker | any) {
  if (eventSpeaker.companyName) return eventSpeaker.companyName
  if (eventSpeaker.user) return eventSpeaker?.user?.profile?.companyName
}

const EventSpeaker = ({
  eventSpeaker,
  textColor
}: {
  eventSpeaker: IEventSpeaker
  textColor: string
}) => {
  const name = getSpeakerName(eventSpeaker)
  const companyName = getSpeakerCompanyName(eventSpeaker)

  return (
    <>
      <span className={clsx('font-semibold', textColor)}>{name}</span>
      {companyName && (
        <span className={clsx('font-normal', textColor)}> @ {companyName}</span>
      )}
    </>
  )
}

interface ISpeakerList {
  orderedSpeakers: IEventSpeaker[]
  textColor?: string
  maxNames: number
}

const SpeakerList = ({
  orderedSpeakers,
  textColor = 'rb-black',
  maxNames
}: ISpeakerList) => (
  <>
    {orderedSpeakers.slice(0, maxNames).map((speaker: IEventSpeaker, i: number) => (
      <Fragment key={`name${i}`}>
        <EventSpeaker eventSpeaker={speaker} textColor={textColor} />
        {i !== orderedSpeakers.slice(0, maxNames).length - 1 && (
          <span className="font-normal">, </span>
        )}
      </Fragment>
    ))}
    {orderedSpeakers.length > maxNames && (
      <span className={textColor}>{` + ${orderedSpeakers.length - maxNames} more`}</span>
    )}
    {orderedSpeakers.length === 0 && <span>Host TBD</span>}
  </>
)

export default SpeakerList
