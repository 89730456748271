import { EventPartsFragment } from 'gql'

import { getCurrentTimezone, getDayOfWeek, utcToNewTimezone } from 'utils/date'
import { getPageFromPath } from 'utils/location'

import { trackEventAction } from './analytics'

export default function sendEventTrackingEvent(
  action: string,
  event: Pick<EventPartsFragment, 'id'> & Partial<EventPartsFragment>,
  userTimezone?: string
) {
  let year: number | undefined,
    month: number | undefined,
    hourOfDay: number | undefined,
    dayOfWeek: string | undefined,
    userStartDatetime: Date | undefined

  if (event.startsAtUtc) {
    const startsAt = event.startsAtUtc
    userStartDatetime = utcToNewTimezone(startsAt, getCurrentTimezone(userTimezone))
    year = userStartDatetime!.getFullYear()
    month = userStartDatetime!.getMonth() + 1
    hourOfDay = userStartDatetime!.getHours()
    dayOfWeek = getDayOfWeek(userStartDatetime)
  }

  trackEventAction({
    action,
    product_page: getPageFromPath(),
    event_id: event.id,
    year,
    month,
    hour_of_day: hourOfDay,
    day_of_week: dayOfWeek,
    event_start_time: userStartDatetime?.toISOString() as string
  })
}
