const BookmarkOutlineIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47501 4.875H50.5239V57.117L29.9994 42.6544L9.47501 57.117V4.875ZM13.725 9.125V48.923L29.9994 37.4552L46.2739 48.923V9.125H13.725Z"
      fill="currentColor"
    />
  </svg>
)

export default BookmarkOutlineIcon
