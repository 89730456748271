import Progress from 'components/Progress'

import { ModuleProgress } from 'typings/scalars'

interface CardProgressBarProps {
  className: string
  sectionId?: string | number
  userProgress?: ModuleProgress | null
  progressPercent?: number
  progressClassName?: string
  progressBarClasses?: {
    background?: string | null
    progress?: string | null
  }
}

export default function CardProgressBar(props: CardProgressBarProps) {
  const {
    className,
    sectionId,
    userProgress,
    progressPercent,
    progressClassName = '',
    progressBarClasses
  } = props

  let contentPercent = 0
  let videoPercent = 0
  let percentComplete = 0
  if (progressPercent) {
    percentComplete = progressPercent
  } else {
    const completed = userProgress && userProgress[`${sectionId}`] === 'completed'
    if (completed) {
      percentComplete = 100
    } else {
      const progressDetails = userProgress && userProgress[`${sectionId}d`]
      if (progressDetails && typeof progressDetails === 'object') {
        contentPercent = progressDetails.content_percent || 0
        videoPercent = progressDetails.video_percent || 0
        percentComplete = contentPercent > videoPercent ? contentPercent : videoPercent
      }
    }
  }

  return (
    <div className={`${className || ''}`}>
      <Progress
        classNames={`h-1 w-full ${progressClassName}`}
        backgroundClass="bg-neutral-200"
        percentComplete={percentComplete}
        progressBarClasses={progressBarClasses}
      />
    </div>
  )
}
