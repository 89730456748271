import { useEffect, useState } from 'react'
import { PopoverPosition } from 'react-tiny-popover'

import BookmarkControls from 'domains/Bookmarks/BookmarkControls'
import ManageBookmarkButton from 'domains/Collections/ManageBookmarkButton'
import { numberNotationFormat } from 'domains/Sanity/lib/helpers'

import BookmarkIcon from 'components/icons/BookmarkIcon'

import {
  BookmarkFolderPartsFragment,
  BookmarkRelatedIdentifiers,
  BookmarkType,
  CmsSectionContentType,
  CourseBookmarkPartsFragment,
  EventBookmarkPartsFragment,
  Maybe,
  ProgramBookmarkPartsFragment,
  useDeleteBookmarkMutation
} from 'gql'

export type ContentBookmarkID = Maybe<string | number | undefined>

const bookmarkTypeByContentType = {
  Project: BookmarkType.PROJECTBOOKMARK,
  Concept: BookmarkType.CONCEPTBOOKMARK,
  Program: BookmarkType.PROGRAMBOOKMARK,
  Lesson: BookmarkType.LESSONBOOKMARK,
  Section: BookmarkType.SECTIONBOOKMARK,
  Unit: BookmarkType.UNITBOOKMARK,
  Resource: BookmarkType.RESOURCEBOOKMARK,
  Artifact: BookmarkType.ARTIFACTBOOKMARK,
  Course: BookmarkType.COURSEBOOKMARK,
  Guide: BookmarkType.GUIDEBOOKMARK,
  Event: BookmarkType.EVENTBOOKMARK,
  BlogPost: BookmarkType.BLOGPOST
}

export interface ContentBookmarkProps {
  cmsContentId?: Maybe<string | number>
  cmsModuleId?: Maybe<string | number>
  cmsProgramId?: Maybe<string | number>
  sanityId?: Maybe<string>
  eventId?: string
  courseSlug?: Maybe<string>
  contentType:
    | CmsSectionContentType
    | 'Program'
    | 'Artifact'
    | 'Course'
    | 'Guide'
    | 'Event'
    | 'BlogPost'
  bookmarkId: ContentBookmarkID
  isInSavedItems: boolean
  setIsInSavedItems: (isInSavedItems: boolean) => void
  onBookmarkChange: (id: string | null) => void
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  bookmark?:
    | ProgramBookmarkPartsFragment
    | CourseBookmarkPartsFragment
    | EventBookmarkPartsFragment
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  currentFolder?: BookmarkFolderPartsFragment | null
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  relatedIdentifiers?: BookmarkRelatedIdentifiers
  dropdownPosition?: PopoverPosition
  savedCount?: number
  showSaveCount?: boolean
}

export default function ContentBookmark(props: ContentBookmarkProps) {
  const {
    cmsContentId,
    cmsModuleId,
    cmsProgramId,
    sanityId,
    eventId,
    courseSlug,
    contentType,
    isInSavedItems,
    setIsInSavedItems,
    onBookmarkChange,
    bookmarkFolders,
    bookmark,
    restoreBookmark,
    currentFolder,
    openAddToBookmarkFolderModal,
    handleRemoveFromFolder,
    relatedIdentifiers,
    dropdownPosition,
    savedCount,
    showSaveCount = false
  } = props
  const showBookmarkControls =
    (!!bookmarkFolders && bookmarkFolders.length !== 0) || currentFolder
  const [bookmarkId, setBookmarkId] = useState<string | number | undefined | null>(
    props.bookmarkId
  )

  useEffect(() => {
    setBookmarkId(props.bookmarkId)
  }, [props.bookmarkId])

  const [deleteBookmark] = useDeleteBookmarkMutation({
    update: (cache, mutationResult) => {
      const deletedBookmarkId = mutationResult?.data?.deleteBookmark?.id
      if (!deletedBookmarkId) return

      const normalizedId = cache.identify({
        id: deletedBookmarkId,
        __typename: 'Bookmark'
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const contentBookmarkAnchor =
    cmsContentId || cmsProgramId
      ? `content-header-bookmark-${cmsContentId || cmsProgramId}`
      : null

  const bookmarkInfo = {
    cmsSectionId: cmsContentId?.toString(),
    cmsModuleId: cmsModuleId?.toString(),
    cmsProgramId: cmsProgramId?.toString(),
    anchor: contentBookmarkAnchor,
    sanityId: sanityId,
    eventId: eventId,
    courseSlug: courseSlug,
    type: bookmarkTypeByContentType[contentType],
    relatedIdentifiers: relatedIdentifiers
  }

  const sendDeleteBookmarkRequest = async () => {
    if (!bookmarkId) return

    const { errors } = await deleteBookmark({
      variables: {
        input: {
          bookmarkId: bookmarkId.toString()
        }
      }
    })
    if (errors) {
      // no user facing error
    } else {
      setBookmarkId(undefined)
      onBookmarkChange(null)
    }
  }

  const saveCountTriggerOverride = (
    <div className="flex w-12 cursor-pointer items-center justify-evenly bg-transparent text-sm">
      <BookmarkIcon
        className={`h-4 w-4 stroke-black group-hover:stroke-inherit ${
          isInSavedItems ? 'fill-current' : 'fill-white'
        }`}
      />
      <span className="font-sans text-sm font-semibold text-rb-black group-hover:text-inherit">
        {numberNotationFormat(savedCount || bookmark?.numPeopleWhoBookmarked || 0)}
      </span>
    </div>
  )

  return (
    <>
      {showBookmarkControls && bookmark && (
        <div className="z-100">
          <BookmarkControls
            bookmark={bookmark}
            onDeleteBookmark={sendDeleteBookmarkRequest}
            restoreBookmark={restoreBookmark}
            handleRemoveFromFolder={handleRemoveFromFolder}
            bookmarkFolders={bookmarkFolders}
            currentFolder={currentFolder}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            triggerElementOverride={showSaveCount ? saveCountTriggerOverride : undefined}
          />
        </div>
      )}
      {!showBookmarkControls && (
        <ManageBookmarkButton
          contentBookmarkId={bookmarkId?.toString()}
          fetchOptions={{}}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          isInSavedItems={isInSavedItems}
          setIsInSavedItems={setIsInSavedItems}
          bookmarkInfo={bookmarkInfo}
          dropdownPosition={dropdownPosition}
          svgOverride={showSaveCount ? saveCountTriggerOverride : undefined}
        />
      )}
    </>
  )
}

ContentBookmark.defaultProps = {
  className: '',
  onBookmarkChange: () => {}
}
