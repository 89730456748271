import React, { PropsWithChildren, useRef, useState } from 'react'

interface ICardTooltip {
  id?: string
  className?: string
  content?: React.ReactNode
  direction?: string
  delay?: number
  xOffset?: string
  yOffset?: string
  useRelPos?: boolean
  hideOnClick?: boolean
}

const CardTooltip = ({
  children,
  className,
  content,
  direction,
  delay,
  xOffset,
  yOffset,
  useRelPos,
  id,
  hideOnClick
}: PropsWithChildren<ICardTooltip>) => {
  const [active, setActive] = useState(false)
  const showTimeout = useRef<ReturnType<typeof setTimeout> | undefined>()
  const hideTimeout = useRef<ReturnType<typeof setTimeout> | undefined>()

  const showTooltip = () => {
    hideTimeout.current && clearTimeout(hideTimeout.current)
    showTimeout.current = setTimeout(() => {
      setActive(true)
    }, delay || 400)
  }

  const hideTooltip = () => {
    showTimeout.current && clearTimeout(showTimeout.current)
    hideTimeout.current = setTimeout(() => {
      setActive(false)
    }, delay || 400)
  }

  const onClick = () => {
    if (hideOnClick) {
      hideTooltip()
    }
  }

  const tooltipStyles: React.CSSProperties = {}
  if (typeof xOffset === 'string') {
    tooltipStyles.left = xOffset
  }
  if (typeof yOffset === 'string') {
    tooltipStyles.top = yOffset
  }

  return (
    <div
      className={`absolute inset-0 ${useRelPos ? 'relative' : ''}`}
      onFocus={showTooltip}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onBlur={hideTooltip}
      onClick={onClick}
    >
      {children}
      {active && (
        <div
          id={id}
          className={`${className} rf-card-tooltip rf-card-tooltip-${direction || 'top'}`}
          style={tooltipStyles}
        >
          {content}
        </div>
      )}
    </div>
  )
}

export default CardTooltip
