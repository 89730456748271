const ClockIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 55C43.8071 55 55 43.8071 55 30C55 16.1929 43.8071 5 30 5C16.1929 5 5 16.1929 5 30C5 43.8071 16.1929 55 30 55Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="4.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30 15V30L40 35"
      fill="none"
      stroke="currentColor"
      strokeWidth="4.25"
      strokeLinejoin="round"
    />
  </svg>
)

export default ClockIcon
