import { ApolloCache, FetchResult } from '@apollo/client'
import produce from 'immer'

import {
  BookmarksFromFolderDocument,
  BookmarksFromFolderQuery,
  CollectionActivityDocument,
  CollectionActivityQuery,
  DeleteFiledBookmarkMutation,
  useDeleteFiledBookmarkMutation
} from 'gql'

const useHandleRemoveFromFolder = (options: any) => {
  const updateBookmarksFromFolderCache = (
    cache: ApolloCache<any>,
    mutationResult: Omit<
      FetchResult<DeleteFiledBookmarkMutation, Record<string, any>, Record<string, any>>,
      'context'
    >
  ) => {
    const deletedFiledBookmark = mutationResult.data?.deleteFiledBookmark?.filedBookmark
    if (!deletedFiledBookmark) return

    const existingSavedItemsData = cache.readQuery<BookmarksFromFolderQuery>({
      query: BookmarksFromFolderDocument,
      variables: {
        folderId: deletedFiledBookmark.bookmarkFolderId
      }
    })
    if (!existingSavedItemsData) return

    const newSavedItemsData = produce(existingSavedItemsData, (draft) => {
      if (!draft?.bookmarksFromFolder) return

      draft.bookmarksFromFolder = draft?.bookmarksFromFolder.filter(
        (bookmark) => bookmark.id !== deletedFiledBookmark.bookmark.id
      )
    })

    cache.writeQuery<BookmarksFromFolderQuery>({
      query: BookmarksFromFolderDocument,
      variables: {
        folderId: deletedFiledBookmark.bookmarkFolderId
      },
      data: newSavedItemsData
    })
  }

  const [RemoveBookmarkFromFolder] = useDeleteFiledBookmarkMutation({
    update(cache, mutationResult) {
      updateBookmarksFromFolderCache(cache, mutationResult)

      const deletedFiledBookmark = mutationResult.data?.deleteFiledBookmark?.filedBookmark
      if (deletedFiledBookmark) {
        const normalizedId = cache.identify({
          id: deletedFiledBookmark.id,
          __typename: 'FiledBookmark'
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      }

      const existingCollectionActivities = cache.readQuery<CollectionActivityQuery>({
        query: CollectionActivityDocument,
        variables: {
          collectionId: deletedFiledBookmark?.bookmarkFolderId,
          page: 1
        }
      })
      const newCollectionActivity =
        mutationResult.data?.deleteFiledBookmark?.collectionActivity

      if (existingCollectionActivities && newCollectionActivity) {
        const newCollectionActivityData = produce(
          existingCollectionActivities,
          (draft) => {
            draft?.collectionActivity?.collectionActivities?.unshift(
              newCollectionActivity
            )
          }
        )

        cache.writeQuery<CollectionActivityQuery>({
          query: CollectionActivityDocument,
          variables: {
            collectionId: deletedFiledBookmark?.bookmarkFolderId,
            page: 1
          },
          data: newCollectionActivityData
        })
      }
    },
    ...options
  })

  return { RemoveBookmarkFromFolder }
}

export default useHandleRemoveFromFolder
