import { twMerge } from 'tailwind-merge'

interface ProgressProps {
  classNames?: string
  percentComplete: number
  backgroundClass?: string
  progressBarClasses?: {
    background?: string | null
    progress?: string | null
  }
}

export default function Progress({
  classNames,
  backgroundClass,
  percentComplete,
  progressBarClasses
}: ProgressProps) {
  const completedClasses = twMerge('bg-rb-green-100', progressBarClasses?.progress)
  const backgroundClasses = twMerge(backgroundClass, progressBarClasses?.background)

  return (
    <div className={`flex overflow-hidden ${classNames}`} role="progressbar">
      <div style={{ width: `${percentComplete}%` }} className={completedClasses} />
      <div style={{ width: `${100 - percentComplete}%` }} className={backgroundClasses} />
    </div>
  )
}

Progress.defaultProps = {
  backgroundClass: 'bg-rb-gray-400',
  progressBarClasses: {
    background: '',
    progress: ''
  }
}
