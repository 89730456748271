import React from 'react'

interface CardProps {
  title?: string
  children: React.ReactNode
  dataTest?: string
}
const Card = ({ children, title, dataTest }: CardProps) => {
  return (
    <div data-test={dataTest} className="rf-rb-card mb-6 bg-white p-3 tl:p-8 lgr:p-10">
      {title && <h3 className="mb-3 text-xl font-medium">{title}</h3>}
      {children}
    </div>
  )
}

export default Card
