import { Maybe } from 'graphql/jsutils/Maybe'

import { COMMITMENT_MODAL_ID } from 'constants/elementIds'

import {
  ClipPartsFragment,
  CohortCurrentUserPartsFragment,
  CohortEventCardPartsFragment,
  CohortEventSameInfoPartsFragment,
  CohortFrontDoorNextPartsFragment,
  CohortViewerPartsFragment,
  CohortViewerScheduledWeekPartsFragment
} from 'gql'

import { isBetweenDates } from 'utils/date'
import { showModal } from 'utils/modal'

export type CohortEvent = CohortEventCardPartsFragment & {
  sameEventInfo: CohortEventSameInfoPartsFragment[]
}

export const buildTodoTasks = (
  userCohort: CohortViewerPartsFragment | CohortFrontDoorNextPartsFragment,
  cohortStart: string | undefined | null,
  hasSignedStatementOfCommitment: Maybe<boolean>,
  currentUser: Pick<CohortCurrentUserPartsFragment, 'slackUserId'>,
  handleHasClickedMeetCohortParticipantsCta: () => void,
  hasClickedMeetCohortParticipantsCta: boolean,
  openRsvpModal: () => void,
  joinSlackHref?: string | null
) => {
  let completionCount = 0
  const todoTasks = [
    {
      content: 'Sign the Statement of Commitment',
      linkText: hasSignedStatementOfCommitment ? 'Signed' : 'View + Sign',
      isTaskCompleted: !!hasSignedStatementOfCommitment,
      disableActionButton: !!hasSignedStatementOfCommitment,
      dataTestId: 'statement-of-commitment',
      linkAction: () => showModal(COMMITMENT_MODAL_ID)
    }
  ]
  if (hasSignedStatementOfCommitment) completionCount += 1

  if (
    cohortStart &&
    userCohort.cohort.batchRsvpTimeData &&
    userCohort.cohort.batchRsvpTimeData.length > 0
  ) {
    todoTasks.push({
      content: 'RSVP to live cohort events',
      linkText: userCohort.showEventRsvpModal ? 'Begin' : 'Edit',
      isTaskCompleted: !userCohort.showEventRsvpModal,
      disableActionButton: false,
      dataTestId: 'rsvp-to-all-events',
      linkAction: openRsvpModal
    })
    if (!userCohort.showEventRsvpModal) completionCount += 1
  }

  if (userCohort.cohort.slackChannelId) {
    todoTasks.push({
      content: 'Meet your cohort participants on Slack',
      linkText: hasClickedMeetCohortParticipantsCta ? 'Say Hello!' : 'View',
      isTaskCompleted: hasClickedMeetCohortParticipantsCta,
      disableActionButton: false,
      dataTestId: 'meet-cohort-participants',
      linkAction: () => {
        if (userCohort) {
          handleHasClickedMeetCohortParticipantsCta()
        }
        if (currentUser.slackUserId) {
          window.open(
            `https://slack.com/app_redirect?channel=${userCohort.cohort.slackChannelId}&team=T01LD82F0M8`,
            '_blank'
          )
        } else {
          joinSlackHref && window.open(joinSlackHref, '_blank')
        }
      }
    })
    if (hasClickedMeetCohortParticipantsCta) completionCount += 1
  }

  return { todoTasks, completionCount }
}

export const combineSameEvents = (
  events?: CohortEventCardPartsFragment[]
): CohortEvent[] => {
  if (!events) return []

  const eventInfo: { [key: string]: CohortEvent } = {}
  events.forEach((event) => {
    if (eventInfo[event.name]) {
      eventInfo[event.name].sameEventInfo.push({
        __typename: 'Event',
        id: `${event.id}`,
        startsAtUtc: event.startsAtUtc,
        endsAtUtc: event.endsAtUtc,
        attendingStatus: event.attendingStatus,
        past: event.past,
        kind: event.kind,
        summaryWistiaCode: event.summaryWistiaCode,
        slug: event.slug,
        uuid: event.uuid,
        preread: event.preread
      })
    } else {
      eventInfo[event.name] = {
        ...event,
        __typename: 'Event',
        id: `${event.id}`,
        sameEventInfo: [
          {
            __typename: 'Event',
            id: `${event.id}`,
            startsAtUtc: event.startsAtUtc,
            endsAtUtc: event.endsAtUtc,
            attendingStatus: event.attendingStatus,
            past: event.past,
            kind: event.kind,
            summaryWistiaCode: event.summaryWistiaCode,
            slug: event.slug,
            uuid: event.uuid,
            preread: event.preread
          }
        ]
      }
    }
  })
  const values = Object.keys(eventInfo).map((key) => eventInfo[key])
  return values
}

export const getPercentCompleteBasedOnStartAndEndDate = (
  startsAt?: string | null,
  endsAt?: string | null
) => {
  if (!startsAt || !endsAt) return 0

  const startDate = new Date(startsAt)
  const endDate = new Date(endsAt)
  const today = new Date()

  const percentage = Math.round(
    ((today.getTime() - startDate.getTime()) /
      (endDate.getTime() - startDate.getTime())) *
      100
  )

  if (percentage < 0) return 0
  else if (percentage > 100) return 100
  else return percentage
}

export const getHiglightSectionData = (
  scheduledWeeks?: CohortViewerScheduledWeekPartsFragment[]
) => {
  if (!scheduledWeeks) return {}

  let allClips: ClipPartsFragment[] = []
  let thisWeekClips: ClipPartsFragment[] = []
  let headerName: string | undefined
  scheduledWeeks.forEach((week) => {
    if (!week.orderedCmsModules) return

    week.orderedCmsModules.forEach((cmsModule) => {
      if (!cmsModule.clips) return
      if (cmsModule.clips.length > 0) {
        allClips = [...allClips, ...cmsModule.clips]
        if (isBetweenDates(week.startsAt, week.endsAt)) {
          thisWeekClips = [...thisWeekClips, ...cmsModule.clips]
          if (!headerName) {
            headerName = cmsModule.name
          }
        }
      }
    })
  })

  return { thisWeekClips: thisWeekClips, allClips: allClips, headerName: headerName }
}

export const getTimeCopy = (minutes: number) => {
  if (minutes < 60) return `${minutes} Minutes`

  const roundedHours = (Math.round((minutes / 60) * 4) / 4).toFixed(2).replace('.00', '')
  return `${roundedHours} ${roundedHours === '1' ? 'Hour' : 'Hours'}`
}
