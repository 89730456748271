import { twMerge } from 'tailwind-merge'

import BadgeableAvatar from 'components/BadgeableAvatar'

import { User } from 'gql'

export type AvatarStackMember = Pick<
  User,
  'id' | 'hasBadge' | 'avatarUrl' | 'kind' | 'fullName'
>

export interface AvatarStackProps {
  className?: string
  members: AvatarStackMember[]
  avatarSize?: number
  maximumAvatars?: number
  totalCount: number
  indent?: number
  direction?: 'right' | 'left'
  avatarClassName?: string
  borderClassName?: string
  zIndex?: number
}

export default function AvatarStack({
  members,
  avatarSize = 24,
  className = '',
  maximumAvatars,
  totalCount,
  indent = 5,
  direction = 'right',
  borderClassName,
  avatarClassName,
  zIndex
}: AvatarStackProps) {
  const exceededMaximum = maximumAvatars ? totalCount > maximumAvatars : false
  return (
    <div className={`flex ${className}`}>
      {members.slice(0, maximumAvatars).map((member, index) => (
        <div
          style={{
            marginLeft: index > 0 ? -indent : 0,
            zIndex: zIndex || (direction === 'right' ? index : totalCount - index)
          }}
          className="flex"
          key={`attendee_${member.id}`}
        >
          <BadgeableAvatar
            user={member}
            avatarUrl={member.avatarUrl}
            width={avatarSize}
            fullName={member.fullName}
            height={avatarSize}
            className={avatarClassName}
            borderClassNames={twMerge('border border-white', borderClassName)}
          />
        </div>
      ))}
      {exceededMaximum && (
        <OverflowDisc
          size={avatarSize}
          count={totalCount - (maximumAvatars || 0)}
          direction={direction}
        />
      )}
    </div>
  )
}

interface OverflowDiscProps {
  size: number
  count: number
  direction: 'right' | 'left'
}

const OverflowDisc = ({ size = 24, count, direction }: OverflowDiscProps) => {
  return (
    <div
      className={`${
        direction === 'right' ? 'z-10' : 'z-0'
      } -ml-1 flex items-center justify-center rounded-full bg-rb-gray-50 font-medium tracking-widest`}
      style={{ height: size, width: size }}
    >
      +{count}
    </div>
  )
}
