import React from 'react'
import { twMerge } from 'tailwind-merge'

import { useDisplayOnHover } from 'components/Popover/usePopover'

import Overlay from './Overlay'
import useDynamicMountPosition from './useDynamicMountPosition'

interface PopoverProps {
  scrollableContainerRef?: any
  containerClassName?: string
  tooltipClassName?: string
  children: React.ReactNode
  cta?: any
  isDisabled?: boolean
  onPopoverDisplayed?: () => void
  html?: React.ReactNode
  text?: any
  offsetTop?: string
  offsetBottom?: string
  zIndex?: string
  mountPositionOverride?: 'top' | 'bottom'
  caretPosition?: 'left' | 'center'
}

export const Popover = ({
  scrollableContainerRef,
  containerClassName,
  tooltipClassName,
  children,
  cta,
  isDisabled,
  onPopoverDisplayed,
  text,
  html,
  offsetTop = 'top-[-100px]',
  offsetBottom = 'bottom-[-100px]',
  zIndex = 'z-10',
  caretPosition = 'left'
}: PopoverProps) => {
  const { mountingElementRef, mountingPoint } =
    useDynamicMountPosition(scrollableContainerRef)

  const { isPopoverDisplayed } = useDisplayOnHover({
    isDisabled,
    mountingElementRef,
    onPopoverDisplayed
  })

  const defaultProps = {
    tooltipClassName,
    offsetTop,
    offsetBottom,
    zIndex,
    caretPosition,
    mountingPoint,
    isPopoverChildren: true
  }

  return (
    <div className={twMerge('relative', containerClassName)} ref={mountingElementRef}>
      {children}
      {isPopoverDisplayed && (
        <Overlay {...defaultProps}>
          {text && <div>{text}</div>}
          {html && html}
          {cta && (
            <div className="flex w-full items-center justify-between pt-3">{cta}</div>
          )}
        </Overlay>
      )}
    </div>
  )
}

export default Popover
