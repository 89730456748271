import notifyError from './errorNotifier'

function createQueryString(data) {
  let queryString = ''
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      queryString += `${key}=${data[key]}&`
    }
  })
  return queryString
}

export function fetchData(uri, params, fetchCallback) {
  const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content
  let fullUri = uri
  if (params) {
    fullUri += `?${createQueryString(params)}`
  }
  window
    .fetch(fullUri, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          fetchCallback(null, data)
        } else {
          const message = { status: response.status, errors: data.errors }
          fetchCallback(message)
        }
      })
    })
    .catch((err) => {
      notifyError(err)
      fetchCallback(err)
    })
}
