import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { sendData } from 'utils/sendData'
import { track } from 'utils/tracking/segment'

import { UpdateContentBlockProgressPayload } from 'typings/payloads'
import { ProgressStatus } from 'typings/scalars'

export interface MarkCompleteButtonProps {
  buttonNum: number
  blockId: string | null
  cmsContentId: string
  status: ProgressStatus | number
  onCtaStatusChange: (a: string, b: ProgressStatus) => void
}

const MarkCompleteButton = ({
  buttonNum,
  blockId,
  status: initialStatus,
  cmsContentId,
  onCtaStatusChange
}: MarkCompleteButtonProps) => {
  const [status, setStatus] = useState(initialStatus)
  const [hover, setHover] = useState(false)

  const setButtonHover = () => {
    setHover(true)
  }

  const clearButtonHover = () => {
    setHover(false)
  }

  const toggleBlockComplete = () => {
    const newStatus = status === 'complete' ? 'incomplete' : 'complete'
    // @ts-ignore - 'Mark Complete Button Clicked' event is not defined in Segment JIRA#REF-5159
    track('Mark Complete Button Clicked', {
      buttonNum,
      blockId,
      cmsContentId,
      newStatus
    })
    const data: UpdateContentBlockProgressPayload = {
      cms_section_id: cmsContentId,
      block_id: blockId,
      status: newStatus
    }
    sendData(
      '/api/v1/user_programs/update_content_block_progress',
      'POST',
      data,
      (err: Error) => {
        if (err) {
          // no user facing error display
        } else {
          if (onCtaStatusChange && blockId) {
            onCtaStatusChange(blockId, newStatus)
          }
          setStatus(newStatus)
        }
      }
    )
  }

  let buttonText = 'Mark Step Complete'
  if (status === 'complete') {
    if (hover) {
      buttonText = 'Unmark Complete'
    } else {
      buttonText = 'Completed!'
    }
  }

  // do not remove rf-button-mark-complete and rf-button-mark-complete__text from below unless refactoring the CMS implementation
  const className = twMerge(
    'rf-button-mark-complete mt-4 flex h-10 w-64 items-center rounded-full bg-rb-gray-500 text-xs font-bold uppercase text-white transition-colors hover:bg-rb-green-100',
    status === 'complete' ? 'bg-rb-green-100 hover:bg-rb-gray-400 group' : ''
  )

  return (
    <button
      type="button"
      onMouseEnter={setButtonHover}
      onMouseLeave={clearButtonHover}
      onClick={toggleBlockComplete}
      className={className}
    >
      <span className="rf-button-mark-complete__text mx-auto my-0 inline-block bg-left bg-no-repeat pl-5 group-hover:bg-[url('/app/assets/images/icon--clear.svg')]">
        {buttonText}
      </span>
    </button>
  )
}

export default MarkCompleteButton
