import React from 'react'
import { twMerge } from 'tailwind-merge'

import { getCaretPosition, trapEvent } from './usePopover'

export interface OverlayProps {
  tooltipClassName?: string
  children: React.ReactNode
  offsetTop?: string
  offsetBottom?: string
  zIndex?: string
  caretPosition?: 'left' | 'center'
  mountingPoint: string
  isPopoverChildren?: boolean | false
}

const Overlay = ({
  tooltipClassName,
  children,
  offsetTop = 'top-[-100px]',
  offsetBottom = 'bottom-[-100px]',
  zIndex = 'z-10',
  caretPosition = 'left',
  mountingPoint,
  isPopoverChildren
}: OverlayProps) => {
  return (
    <div
      className={twMerge(
        'rf-tooltip-caret absolute flex w-full max-w-[200px] flex-col items-center rounded text-base font-normal text-white shadow-lg shadow-pop-menu',
        mountingPoint === 'top' && `${offsetTop}`,
        mountingPoint === 'bottom' && `${offsetBottom}`,
        tooltipClassName,
        zIndex,
        isPopoverChildren && 'left-[10%] w-80 max-w-[320px] bg-rb-gray-400 p-3',
        getCaretPosition(caretPosition, mountingPoint)
      )}
      onClick={trapEvent}
    >
      {children}
    </div>
  )
}

export default Overlay
