interface IModuleProgressBar {
  progressPercent: number
}

const ModuleProgressBar = ({ progressPercent }: IModuleProgressBar) => (
  <div style={{ height: '5px' }} className="flex flex-row">
    <div style={{ width: `${progressPercent}%` }} className="bg-rb-green-100" />
    <div style={{ width: `${100 - progressPercent}%` }} className="bg-rb-gray-50" />
  </div>
)

export default ModuleProgressBar
