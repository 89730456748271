import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

interface RfHeader3Props {
  id?: string
  className?: string
}

const RfHeader3 = ({ id, children, className }: PropsWithChildren<RfHeader3Props>) => (
  <h3
    id={id}
    className={twMerge(
      'rf-h3 mb-0',
      className // allow color, margin and padding overrides
    )}
  >
    {children}
  </h3>
)

export default RfHeader3
