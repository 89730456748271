import produce from 'immer'

import {
  BookmarksFromFolderDocument,
  BookmarksFromFolderQuery,
  useCreateFiledBookmarkMutation
} from 'gql'

const useHandleAddToFolder = (options: any) => {
  const [AddBookmarkToFolder] = useCreateFiledBookmarkMutation({
    update(cache, { data }) {
      const newFiledBookmark = data?.createFiledBookmark?.filedBookmark
      if (!newFiledBookmark) return

      const existingSavedItemsData = cache.readQuery<BookmarksFromFolderQuery>({
        query: BookmarksFromFolderDocument,
        variables: {
          folderId: newFiledBookmark.bookmarkFolderId
        }
      })
      if (!existingSavedItemsData) return

      const newSavedItemsData = produce(existingSavedItemsData, (draft) => {
        draft?.bookmarksFromFolder?.unshift(newFiledBookmark.bookmark)
      })

      cache.writeQuery<BookmarksFromFolderQuery>({
        query: BookmarksFromFolderDocument,
        variables: {
          folderId: newFiledBookmark.bookmarkFolderId
        },
        data: newSavedItemsData
      })
    },
    ...options
  })

  return { AddBookmarkToFolder }
}

export default useHandleAddToFolder
