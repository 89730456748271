import { useHistory } from 'react-router-dom'

import { getTimeCopy } from 'domains/CohortViewer/utils'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import Popover from 'components/Popover/Popover'
import ModuleProgramIcon from 'components/cards/elements/ModuleProgramIcon'
import ModuleProgressBar from 'components/cards/elements/ModuleProgressBar'
import BookmarkOutlineIcon from 'components/icons/BookmarkOutlineIcon'
import ClockIcon from 'components/icons/ClockIcon'
import PlayCircleBorderIcon from 'components/icons/PlayCircleBorderIcon'

import { ClipPartsFragment } from 'gql'

const getButtonCopy = (progressPercent: number) => {
  if (progressPercent === 0) return 'Begin'
  if (progressPercent === 100) return 'View'
  return 'Continue'
}

export interface FullWidthModuleCardProps {
  id?: string
  name: string
  href: string
  numBookmarks?: number | null
  numLessons: number
  estimatedTime: number
  progressPercent: number
  shortDescription: string
  programIconImageUrl: string
  published: boolean
  publishAt?: string
  clips?: ClipPartsFragment[]
  isSmallSize?: boolean
  weekNumber?: number | null
  releaseDate?: string | null
  isProject: boolean
}

const FullWidthModuleCard = ({
  id,
  name,
  href,
  numBookmarks,
  progressPercent,
  programIconImageUrl,
  estimatedTime,
  shortDescription,
  numLessons,
  published,
  isSmallSize,
  weekNumber,
  releaseDate,
  isProject
}: FullWidthModuleCardProps) => {
  const history = useHistory()

  const onClick = () => {
    history.push(href)
  }

  return (
    <div
      onClick={published ? onClick : undefined}
      className={`rf-rb-card-interactive flex h-full w-full flex-col pb-2 sm:max-h-[110px] ${
        published && 'cursor-pointer'
      }`}
      data-test={`module-card-${id}`}
    >
      <ModuleProgressBar progressPercent={progressPercent} />
      <div className={`flex ${!isSmallSize && '&& px-3 pb-3'}`}>
        <div className="flex justify-center p-3">
          <div className="w-[40px]">
            <ModuleProgramIcon
              programIconImageUrl={programIconImageUrl}
              isProject={isProject}
            />
          </div>
        </div>
        <div className="flex grow flex-col pt-5">
          <p className="mb-1 text-lg font-medium leading-6">{name}</p>
          <div className="flex flex-col">
            <div className="block flex-row justify-between sm:flex">
              <div>
                <div className="hidden sm:flex">
                  <div className="mb-2 text-sm leading-4 line-clamp-1">
                    {shortDescription}
                  </div>
                </div>
                <div className="block flex-row justify-between sm:flex">
                  {published ? (
                    <div className="flex flex-row items-center">
                      {Boolean(estimatedTime) && estimatedTime > 0 && (
                        <>
                          <div className="h-[15px] w-[15px] text-rb-gray-300">
                            <ClockIcon className="min-h-[15px] min-w-[15px] fill-current" />
                          </div>
                          <span className="ml-2 mr-3 text-xs leading-4 text-rb-gray-300">
                            {getTimeCopy(estimatedTime)}
                          </span>
                        </>
                      )}
                      <div className="hidden flex-row items-center sm:flex">
                        {Boolean(numLessons) && numLessons > 0 && (
                          <>
                            <div className="h-[15px] w-[15px] text-rb-gray-300">
                              <PlayCircleBorderIcon className="min-h-[15px] min-w-[15px] fill-current" />
                            </div>
                            <span className="ml-2 mr-3 text-xs leading-4 text-rb-gray-300">
                              {numLessons} Lessons
                            </span>
                          </>
                        )}
                        {!!numBookmarks && (
                          <>
                            <div className="h-[15px] w-[15px] text-rb-gray-300">
                              <BookmarkOutlineIcon className="min-h-[15px] min-w-[15px]" />
                            </div>
                            <span className="ml-0.5 text-xs leading-4 tracking-widest text-rb-gray-300">
                              {numBookmarks}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex cursor-default items-center">
                      <SVGIcon name="lock" height="20" width="16" fill="#a2a1a2" />
                      <div className="pl-2 text-xs font-medium text-rb-gray-300">
                        Releasing {releaseDate}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-row items-end justify-end px-4 pt-1 pb-1">
                {published ? (
                  <Button
                    variant="outline"
                    size="x-small"
                    shape="rounded-full"
                    onClick={onClick}
                    className="h-8 w-32"
                  >
                    {getButtonCopy(progressPercent)}
                  </Button>
                ) : (
                  <Popover
                    text={`Module will be released before week ${weekNumber}`}
                    tooltipClassName="text-xs left-0 p-1 py-1.5 w-28 text-center"
                    offsetBottom="-bottom-[80px]"
                    caretPosition="center"
                  >
                    <Button
                      variant="outline"
                      size="x-small"
                      shape="rounded-full"
                      className="h-8 w-32"
                      disabled
                    >
                      Begin
                    </Button>
                  </Popover>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

FullWidthModuleCard.defaultProps = {
  isProject: false,
  isSmallSize: false
}

export default FullWidthModuleCard
