import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

export const asyncLaunchDarklyProvider = async () => {
  const envId = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID || 'no ID set'

  return await asyncWithLDProvider({
    clientSideID: envId
  })
}

export default asyncLaunchDarklyProvider
