import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'

import useOnClickOutside from 'hooks/useOnClickOutside'

interface CalendarDropdownMenuProps {
  containerClassName?: string
  offset?: string
  children?: React.ReactNode
  onClose?: any
  defaultStyles?: boolean
}

export const useCalendarDropDown = () => {
  const [isAddToCalendarOpen, setIsAddToCalendarOpen] = useState<boolean>(false)

  useEffect(() => {
    if (isAddToCalendarOpen) {
      // Refreshing AddEvent api as it is synchronous
      // @ts-ignore
      window.addeventatc?.refresh()
    }
  }, [isAddToCalendarOpen])

  return { isAddToCalendarOpen, setIsAddToCalendarOpen }
}

const CalendarDropdownMenu = ({
  containerClassName,
  offset,
  children,
  onClose,
  defaultStyles = true
}: CalendarDropdownMenuProps) => {
  const mountingElementRef = useRef(null)
  useOnClickOutside(mountingElementRef, onClose)

  return (
    <div
      data-test={'calendar-dropdown'}
      className={clsx(
        defaultStyles && 'left-1/2 -translate-x-1/2',
        containerClassName,
        offset
      )}
      ref={mountingElementRef}
    >
      {children}
    </div>
  )
}

export default CalendarDropdownMenu
