import pluralize from 'pluralize'

import AvatarStack from 'components/AvatarStack'
import { SVGIcon } from 'components/Icon'
import AttendButton, {
  AttendButtonContainerProps
} from 'components/cards/EventCard/AttendButton'
import SpeakerList from 'components/cards/EventCard/SpeakerList'

import { EventCurrentUserPartsFragment, EventPartsFragment, EventSpeaker } from 'gql'

import {
  DAY_OF_MONTH_FORMAT,
  HOUR_MIN_FORMAT,
  MONTH_ABBREV_FORMAT,
  formatInTimezone,
  getCurrentTimezone,
  getDurationInMinutes,
  getTimezoneAbbreviation
} from 'utils/date'
import eventSignUpAccess from 'utils/eventAccess'

export interface EventCardProps {
  currentUser: EventCurrentUserPartsFragment | null
  event: EventPartsFragment
  attendCallback?: AttendButtonContainerProps['attendCallback']
  notAttendCallback?: AttendButtonContainerProps['notAttendCallback']
  eventPageQueryString?: string
  contentClickDisabled?: boolean
  disabledButton?: boolean
}

const EventCard = ({
  event,
  attendCallback,
  notAttendCallback,
  currentUser,
  eventPageQueryString = '',
  contentClickDisabled = false,
  disabledButton = false
}: EventCardProps) => {
  const canViewEvent = !currentUser || currentUser.can.viewEvent
  const orderedSpeakers: EventSpeaker[] = ([] as EventSpeaker[])
    .concat(event.speakers as any)
    .sort((speaker: EventSpeaker) => (speaker.kind === 'host' ? -1 : 1))

  const tzAbbrev = getTimezoneAbbreviation(currentUser?.timezone, event.startsAtUtc)
  const userTimezone = getCurrentTimezone(currentUser?.timezone)

  const linkToEvent = (e: any) => {
    if (contentClickDisabled) return false

    const clickedToggle = e.target.closest('.prevent-default')

    if (!clickedToggle && canViewEvent) {
      if (event.summaryWistiaCodeOptional) {
        // there react router push cannot be used because this component is rendered in /member/{id} where context is not available
        window.location.href = `/events/${event.id}-${event.slug}/summary${eventPageQueryString}`
      } else {
        window.location.href = `/events/${event.id}-${event.slug}${eventPageQueryString}`
      }
    }
  }

  let bgColor = 'bg-rb-blue-100'
  if (event.kind === 'Program Event' || event.kind === 'Cohort Event') {
    bgColor = 'bg-rb-blue-100'
  } else if (event.kind === 'Conversation') {
    bgColor = 'bg-rb-blue-100'
  } else if (event.kind === 'Community') {
    bgColor = 'bg-rb-blue-100'
  }

  const typeText =
    (event.kind === 'Program Event' || event.kind === 'Cohort Event') &&
    event.cmsProgramOptional &&
    event.cmsProgramOptional.name
      ? event.cmsProgramOptional.name.replace(' Deep Dive', '')
      : event.kind

  const eventDurationInMinutes = getDurationInMinutes(event.startsAtUtc, event.endsAtUtc)
  const eventDurationInHour = parseInt(eventDurationInMinutes) / 60
  const eventDuration =
    eventDurationInHour < 1
      ? `${eventDurationInMinutes} minutes`
      : pluralize('hour', eventDurationInHour, true)

  const timeOfEventInLocalTimeZone = `${formatInTimezone(
    event.startsAtUtc,
    userTimezone,
    HOUR_MIN_FORMAT
  )} - ${formatInTimezone(event.endsAtUtc, userTimezone, HOUR_MIN_FORMAT)} `

  return (
    <div
      data-test={`event-card-${event.id}`}
      onClick={linkToEvent}
      className="rf-rb-card-interactive flex min-h-[275px] flex-1 cursor-pointer flex-col"
    >
      <div
        className={`flex h-[150px] flex-col justify-between bg-cover bg-center bg-no-repeat p-4 ${bgColor} uk-disabled`}
      >
        <div className="flex flex-row">
          <div
            className="flex h-[50px] w-[50px] min-w-[50px] flex-col items-center justify-center rounded-full bg-white backdrop-grayscale"
            style={{ zIndex: 6, position: 'relative' }}
          >
            <div
              className="uk-position-center flex flex-col items-center justify-center"
              style={{ transform: 'translate(-50%, -45%)' }}
            >
              <p className="m-0 text-xs font-medium leading-[14px] tracking-widest text-rb-gray-400">
                {formatInTimezone(
                  event.startsAtUtc,
                  userTimezone,
                  MONTH_ABBREV_FORMAT
                ).toUpperCase()}
              </p>
              <p className="mx-0 mt-[-7px] mb-0 text-[21px] font-medium">
                {formatInTimezone(
                  event.startsAtUtc,
                  userTimezone,
                  DAY_OF_MONTH_FORMAT
                ).toUpperCase()}
              </p>
            </div>
          </div>
          <div className="text-base">
            <AvatarStack
              members={orderedSpeakers.map((speaker) => ({
                ...speaker,
                avatarUrl: speaker.user.profile.avatarUrl,
                hasBadge: false,
                fullName: speaker.user.fullName
              }))}
              totalCount={orderedSpeakers.length}
              maximumAvatars={2}
              avatarSize={50}
              indent={4}
              direction={'left'}
            />
          </div>
          {!canViewEvent && (
            <div className="uk-margin-auto-left">
              <span
                uk-tooltip="title: Join now for access.; cls: uk-active rf-tooltip-down-arrow rf-tooltip-down-arrow--first; pos: top; offset: 20"
                className="rb-gray-500-circle rb-gray-500-circle--30 flex items-center justify-center"
              >
                <SVGIcon name="private-user-lock" width="16" height="20" fill="white" />
              </span>
            </div>
          )}
        </div>
        <div>
          <p className="uk-text-uppercase m-0 pb-1 font-sans text-xs font-medium leading-[14px] tracking-widest text-rb-black opacity-75">
            {typeText}
          </p>
          <p
            data-test={`event-card-title-${event.id}`}
            className="m-0 font-sans text-base font-semibold leading-[17px] text-rb-black line-clamp-2"
          >
            {event.name}
          </p>
        </div>
      </div>

      <div className="flex flex-1 flex-col justify-between p-4">
        <div className="uk-disabled max-h-[66px] min-h-[50px] font-sans text-[15px] font-normal leading-[17px] text-rb-gray-400 line-clamp-3">
          <SpeakerList orderedSpeakers={orderedSpeakers} maxNames={2} />
        </div>
        <div
          data-test="event-card-button"
          className="flex flex-row items-center justify-between"
        >
          {event.past ? (
            <div className="uk-disabled flex">
              <div className="font-sans text-xs font-medium uppercase leading-[14px] tracking-wider text-rb-gray-300 ">
                {eventDuration}
              </div>
            </div>
          ) : (
            <div className="uk-disabled">
              <div className="font-sans text-xs font-medium leading-[14px] tracking-wider text-rb-gray-300">
                {timeOfEventInLocalTimeZone}
              </div>
              <div className="font-sans text-xs font-medium leading-[14px] tracking-wider text-rb-gray-300">
                {tzAbbrev ? `(${tzAbbrev})` : ''}
              </div>
            </div>
          )}

          {currentUser?.id && canViewEvent && !disabledButton && (
            <AttendButton
              dataTestId={`event-card-button-${event.id}`}
              timezone={userTimezone}
              event={event}
              attendCallback={attendCallback}
              notAttendCallback={notAttendCallback}
              disable={!eventSignUpAccess(currentUser, event)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EventCard
