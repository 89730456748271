import { twMerge } from 'tailwind-merge'

interface InfoCardProps {
  headerImage: string
  header: string
  content: string
  textRight?: boolean
}

export const InfoCard = ({ headerImage, header, content, textRight }: InfoCardProps) => {
  return (
    <div className="mx-auto mb-24 flex max-w-5xl flex-col items-center md:flex-row">
      <div
        className={twMerge(
          'max-w-xs md:order-last',
          textRight ? 'md:order-first md:mr-24' : 'md:ml-24'
        )}
      >
        <img alt="" className="" src={headerImage} />
      </div>
      <div className="flex flex-col">
        <h2 className="mb-5 text-xl tracking-tight sm:text-2xl lg:text-4xl">{header}</h2>
        <p className="mb-0 text-base">{content}</p>
      </div>
    </div>
  )
}
