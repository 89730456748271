import PropTypes from 'prop-types'

export const ExampleCard = (props) => {
  const { deliverableTitle, companyName, companyImgUrl, deliverableUrl } = props

  const handleClick = (e) => {
    e.preventDefault()

    window.open(deliverableUrl)
  }

  return (
    <a
      href={deliverableUrl}
      onClick={handleClick}
      className="example-card border-[rgba(0, 0, 0, 0.1)] !mt-0 h-[115px] w-[250px] rounded-b border-t border-solid bg-white p-[15px] text-rb-gray-500 hover:text-rb-teal-200 hover:shadow-discussion-card"
      target="_blank"
      rel="noreferrer"
    >
      <div className="relative h-full w-full bg-[url('/app/assets/images/icon--action-arrow-upright.svg')] bg-right-top bg-no-repeat">
        <div className="h-[30px] w-[100px]">
          <img
            className="!m-0 max-h-full !w-auto border-none"
            src={companyImgUrl}
            alt={companyName}
          />
        </div>
        <div className="absolute bottom-0">
          <div className="mb-2 pt-[35px] font-sans text-[10px] uppercase leading-[14px] tracking-widest text-rb-gray-300">
            Example
          </div>
          <div className="rf-truncated-text-2-lines font-sans !text-sm font-medium">
            {deliverableTitle}
          </div>
        </div>
      </div>
    </a>
  )
}

ExampleCard.propTypes = {
  exampleId: PropTypes.number,
  name: PropTypes.string,
  companyName: PropTypes.string,
  companyImgUrl: PropTypes.string,
  deliverableTitle: PropTypes.string,
  deliverableUrl: PropTypes.string
}
