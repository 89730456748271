import React, { RefObject, useCallback, useEffect, useState } from 'react'

interface UseDisplayOnHoverParams {
  isDisabled?: boolean
  mountingElementRef?: RefObject<any>
  onPopoverDisplayed?: () => void
}

export const useDisplayOnHover = ({
  isDisabled,
  mountingElementRef,
  onPopoverDisplayed
}: UseDisplayOnHoverParams) => {
  const [isPopoverDisplayed, setIsPopoverDisplayed] = useState(false)

  const displayPopover = useCallback(() => {
    if (isDisabled) return

    setIsPopoverDisplayed(true)

    // Call user-provided event callback.
    onPopoverDisplayed?.()
  }, [isDisabled, onPopoverDisplayed])

  const hidePopover = useCallback(() => {
    setIsPopoverDisplayed(false)
  }, [])

  useEffect(() => {
    const mountingElement = mountingElementRef?.current

    mountingElement.addEventListener('mouseenter', displayPopover)
    mountingElement.addEventListener('mouseleave', hidePopover)

    return () => {
      mountingElement.removeEventListener('mouseenter', displayPopover)
      mountingElement.removeEventListener('mouseleave', hidePopover)
    }
  }, [mountingElementRef, displayPopover, hidePopover])

  return { isPopoverDisplayed }
}

export const trapEvent = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation()
}

export const getCaretPosition = (position: string, mountPosition: string) => {
  if (position === 'left' && mountPosition === 'top') {
    return 'rf-tooltip-caret-bottom-left'
  } else if (position === 'center' && mountPosition === 'top') {
    return 'rf-tooltip-bottom'
  } else if (position === 'left' && mountPosition === 'bottom') {
    return 'rf-tooltip-caret-top-left'
  } else if (position === 'center' && mountPosition === 'bottom') {
    return 'rf-tooltip-caret-top'
  }

  return 'rf-tooltip-caret'
}
