import { subMinutes } from 'date-fns'

import {
  FULL_DATE_AND_TIME_ABBREV_MONTH_FORMAT,
  formatInTimezone,
  isBetweenDates
} from 'utils/date'

export const isEventInSession = (startsAtUtc: string, endsAtUtc: string) => {
  return isBetweenDates(subMinutes(Date.parse(startsAtUtc), 10).toUTCString(), endsAtUtc)
}

export const hasEventEnded = (endsAtUtc: string) => {
  return Date.now() > Date.parse(endsAtUtc)
}

export const getAddEventTime = (time: string, timezone?: string | null) => {
  return formatInTimezone(time, timezone, FULL_DATE_AND_TIME_ABBREV_MONTH_FORMAT)
}
