import clsx from 'clsx'
import React from 'react'

export interface CompanyBadgeProps {
  cardLogoUrl: string
  name: string
  className?: string
}

export const CompanyBadge = ({
  name,
  cardLogoUrl,
  className = ''
}: CompanyBadgeProps) => (
  <div className={clsx('flex justify-center rounded-sm bg-white px-2 py-1', className)}>
    <img src={cardLogoUrl} alt={name} className="h-full max-h-4" />
  </div>
)

export default CompanyBadge
